import { render, staticRenderFns } from "./BSCBridge.vue?vue&type=template&id=6049c0af&scoped=true"
import script from "./BSCBridge.vue?vue&type=script&lang=js"
export * from "./BSCBridge.vue?vue&type=script&lang=js"
import style0 from "./BSCBridge.vue?vue&type=style&index=0&id=6049c0af&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6049c0af",
  null
  
)

export default component.exports